<div class="social-icons-wrapper">
  <a target="_blank" href="https://linkedin.com/in/alexey-rubinchyk-0b511a51"
    ><div class="linkedin-icon"></div
  ></a>
  <a target="_blank" href="https://instagram.com/rubinchyk/"
    ><div class="instagram-icon"></div
  ></a>
  <a target="_blank" href="https://twitter.com/azovl"
    ><div class="twitter-icon"></div
  ></a>
  <a target="_blank" href="https://facebook.com/alexey.rubinchyk"
    ><div class="facebook-icon"></div
  ></a>
  <a target="_blank" href="https://github.com/rubinchyk"
    ><div class="github-icon"></div
  ></a>
</div>
