import { Component, OnInit } from "@angular/core";
import { wallOfHonorsImages } from "./model/wall-of-honor-images.config";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
} from "ngx-gallery-9";

@Component({
  selector: "app-wall-of-honor",
  templateUrl: "./wall-of-honor.component.html",
  styleUrls: ["./wall-of-honor.component.scss"],
})
export class WallOfHonorComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() {}

  ngOnInit(): void {
    // https://github.com/SaeedSpate/ngx-gallery-9
    this.galleryOptions = [
      {
        width: "80vw",
        height: "calc(76vh - 7vh)",
        thumbnailsColumns: 10,
        lazyLoading: true,
        imageDescription: true,
        imageArrowsAutoHide: true,
        imageSwipe: true,
        imageAnimation: NgxGalleryAnimation.Fade,
        previewInfinityMove: true,
        previewZoom: true,
        fullscreenIcon: "fa fa-arrows-alt",
        imageSize: "big",
      }
    ];

    this.galleryImages = wallOfHonorsImages;
  }
}
