<app-header></app-header>
<div class="main-wrapper">
    <div class="left-side">
        <app-slogan></app-slogan>
        <app-social-icons></app-social-icons>
    </div>

    <div class="center-side">
        <router-outlet></router-outlet>
        <app-footer-menu></app-footer-menu>
    </div>
</div>

<app-auto-download></app-auto-download>
