import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"]
})
export class SliderComponent implements OnInit {
  intervalId;
  intervalMs = 10000;
  active = 1;
  slides = [
    {
      title: "PODCAST",
      sub: "FRONTEND NEWS>",
      icon: "podcast",
      description:
        "The latest news related to Web Development, articles, releases, " +
        "conferences and much more."
    },
    {
      title: "MAIN RESUME",
      sub: "ALEXEY RUBINCHYK>",
      icon: "resume",
      description:
        "Enthusiastic and creative software developer with seven years of " +
        "Software Development Life Cycle experience. Over ten years of experience in " +
        "web programming, development and Implementation of Web/Mobile Applications. " +
        "Complete knowledge of Networking and communication devices. Quick learner that " +
        "rapidly adapts to emerging technologies. Proven ability to take charge of projects " +
        "and ensure successful completion."
    },
    {
      title: "BLOG",
      sub: "NEWS, UPDATES, PROJECTS, CHANGES IN LIFE>",
      icon: "blog",
      description:
        "Life is changing, and with it, I am changing. " +
        "Preferences change, knowledge and experience are added. " +
        "You can find out about all this by visiting my blog. "
    },
    {
      title: "PROJECTS",
      sub: "ABILITY TO SOLVE COMPLEX PROBLEMS IN ELEGANT AND EFFICIENT WAYS>",
      icon: "projects",
      description:
        "Full SDLC. " +
        "From UI/UX design, frontend, backend to server management. " +
        "Wide coverage of modern technologies. "
    },
    {
      title: "WALL OF HONORS",
      sub: "LEARNING AS HOBBY>",
      icon: "wall",
      description:
        "Diplomas. MBA. " +
        "Certificates. Harvard. Pluralsight. Linux Foundation. " +
        "PGP Signatures. "
    }
  ];

  slideChange(slideNum) {
    this.clearChangeInterval();

    this.active = slideNum;

    this.setChangeInterval();
  }

  setChangeInterval(): void {
    this.intervalId = setInterval(() => {
      const slidesLength = this.slides.length;

      if (this.active !== slidesLength) {
        this.active = this.active + 1;
      } else {
        this.active = 1;
      }
    }, this.intervalMs);
  }

  clearChangeInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  ngOnInit(): void {
    this.setChangeInterval();
  }

  ngOnDestroy(): void {
    this.clearChangeInterval();
  }
}
