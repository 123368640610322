<div class="page-wrapper">
    <div class="resume-wrapper">
        <a class="main-page" routerLink="/" routerLinkActive="active"
        >RETURN TO MAIN PAGE</a
        >

        <div class="content">
            <div class="left-border"></div>
            <div class="header">
                <div class="col">+972 58 55-77-44-6</div>
                <div class="col">
                    <a href="mailto:alexeyrubinchyk@gmail.com"
                    >alexeyrubinchyk@gmail.com</a
                    >
                </div>
                <div class="col">
                    <a href="https://linkedin.com/in/alexey-rubinchyk-0b511a51"
                    >LinkedIn</a
                    >
                </div>
            </div>

            <div class="title-wrapper">
                <div class="left-side">
                    <div class="photo"></div>
                </div>
                <div class="right-side">
                    <div class="name">ALEXEY</div>
                    <div class="last-name">RUBINCHYK</div>
                    <div class="specialization">Full Stack Web Developer</div>
                </div>
            </div>

            <section class="objective">
                <div class="section-title-wrapper">
                    <div class="section-title">OBJECTIVE</div>
                    <div class="section-title-line"></div>
                </div>

                <div class="text">
                    My goal is to obtain a dynamic, challenging opportunity that
                    contributes to the outstanding success of the business via 11+ years
                    Information Technology experience. In achieving these goals,
                    successful teamwork and continuous improvement.
                </div>
                <div class="section-title-end"></div>
            </section>

            <section class="summary">
                <div class="section-title">SUMMARY</div>

                <div class="text">
                    Enthusiastic and creative software developer with seven years of
                    Software Development Life Cycle experience. Over seven years of
                    experience in web programming, development and Implementation of Web
                    Applications. Complete knowledge of Networking and communication
                    devices. Quick learner that rapidly adapts to emerging technologies.
                    Proven ability to take charge of projects and ensure successful
                    completion.
                </div>
                <div class="section-title-end"></div>
            </section>

            <section class="work-experience">
                <div class="section-title">WORK EXPERIENCE</div>

                <br/>

                <div class="row">
                    <div class="left">
                        <div class="company">(AdMaven) WebPick Internet Holdings Ltd.</div>
                        <div class="years">Feb 2018 - Present</div>
                        <div class="location">Israel, Tel-Aviv</div>
                    </div>
                    <div class="right">
                        <div class="specialization">SENIOR FULL STACK DEVELOPER</div>
                        <div class="text">
                            <ul>
                                <li>EcmaScript, TypeScript, Python, NodeJs</li>
                                <li>AngularJs, Angular, React (Redux/Recoil)</li>
                                <li>UI/UX development (Adobe Photoshop, Illustrator, Adobe XD, Figma)</li>
                                <li>Wordpress development. Plugins and templates</li>
                                <li>PWA, Chrome extensions (PlasmoHQ)</li>
                                <li>D3</li>
                                <li>Express, HapiJS, Puppeteer</li>
                                <li>MongoDb, Mongoose, Strapi</li>
                                <li>Cypress</li>
                                <li>CSS, SCSS, Bootstrap 4, Material</li>
                                <li>Grunt, Gulp, Webpack</li>
                                <li>Git, SVN</li>
                                <li>EC2, S3, Lambda</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">CITRIN.Tech</div>
                        <div class="years">Aug 2016 - Feb 2018</div>
                        <div class="location">Israel, Ramat Gan</div>
                    </div>
                    <div class="right">
                        <div class="specialization">SENIOR FULL STACK DEVELOPER</div>
                        <div class="text">
                            Hybrid applications, web projects with different complexity. All
                            development cycle from idea, design to production version,
                            implementation on all levels.
                            <ul>
                                <li>UI/UX development</li>
                                <li>
                                    Development progressive web applications for iOS, Android
                                </li>
                                <li>
                                    JavaScript (TypeScript), AngularJS 1x 2x 5, Angular UI,
                                    Bootstrap 4, JQuery (UI), Ionic, D3, Three.JS, Electron,
                                    Socket.IO, Cordova
                                </li>
                                <li>SCSS, Less</li>
                                <li>Gulp, Webpack</li>
                                <li>PHP (Yii 1, 2), Node.js, Guzzle, Mink</li>
                                <li>PhantomJS, CasperJS</li>
                                <li>
                                    Linux, NGINX (configuration), MySQL (model prototyping,
                                    structure implementations, CRUD)
                                </li>
                                <li>Docker (configuration)</li>
                                <li>AWS Lambda (storing information), Coralogix (Analytics)</li>
                                <li>SCRUM, Kanban</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Invest.com</div>
                        <div class="years">Jun 2015 - Aug 2016</div>
                        <div class="location">Israel, Herzliya</div>
                    </div>
                    <div class="right">
                        <div class="specialization">FRONTEND DEVELOPER</div>
                        <div class="text">
                            Project Offering smart investing opportunities include portfolio
                            management, access to a range of alternative investment strategies
                            and trading platforms.
                            <ul>
                                <li>UI/UX</li>
                                <li>Landing pages</li>
                                <li>JavaScript, Angular JS, Angular UI, jQuery, jQuery UI</li>
                                <li>
                                    CSS3, HTML5, Twitter Bootstrap, SCSS, LESS, Grunt, Gulp, D3,
                                    Yeoman
                                </li>
                                <li>Ionic, Wordpress theme creating</li>
                                <li>Git</li>
                                <li>Mobile development (Ionic, Cordova)</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">​GRT Corporation | KM Ware US</div>
                        <div class="years">Sep 2014 – Mar 2015</div>
                        <div class="location">United States</div>
                    </div>
                    <div class="right">
                        <div class="specialization">FRONTEND DEVELOPER</div>
                        <div class="text">
                            Development of the frontend and backend of various levels and
                            complexity
                            <ul>
                                <li>Prototyping</li>
                                <li>
                                    Creating a common style website design and usability design
                                </li>
                                <li>
                                    Frontend development (JavaScript, jQuery, jQuery UI, CSS3,
                                    Twitter Bootstrap v.2.x, templating Mustache.js, LESS, Grunt,
                                    Bower)
                                </li>

                                <li>Apache Subversion SVN / Git</li>
                                <li>Mobile development:</li>
                                <li>
                                    Fluid design (Flexbox, @media, viewport, devicePixelRatio,
                                    etc.)
                                </li>
                                <li>Responsive images</li>
                                <li>Optimization</li>
                                <li>Black box testing, negative testing</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">TradePremium</div>
                        <div class="years">Feb 2014 – Mar 2015</div>
                        <div class="location">United States</div>
                    </div>
                    <div class="right">
                        <div class="specialization">FULL STACK WEB DEVELOPER</div>
                        <div class="text">
                            Trade intermediary social network.
                            <ul>
                                <li>Prototyping</li>
                                <li>
                                    Creating a common style website design and usability design
                                </li>
                                <li>Frontend development (jQuery, CSS3, jQueryUI, Yeoman)</li>
                                <li>Mobile development</li>
                                <li>
                                    Backend development using Yii v.1.x framework, AmazonEC2,
                                    Digital Ocean
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">EuroMD</div>
                        <div class="years">Aug 2013 – Feb 2014</div>
                        <div class="location">Poland</div>
                    </div>
                    <div class="right">
                        <div class="specialization">FULLSTACK WEB DEVELOPER</div>
                        <div class="text">
                            Medical portal (euromd.com)
                            <ul>
                                <li>Prototyping web interfaces using Axure and Pencil.</li>
                                <li>
                                    Design and creation of web interfaces using Adobe Photoshop
                                    CC, CSS3,
                                </li>
                                HTML5.
                                <li>
                                    Implement solutions using JavaScript, jQuery, Twitter
                                    Bootstrap 3, HTML5
                                </li>
                                <li>
                                    Backend development using the Yii framework v.2.x, MySQL,
                                    Composer
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Daxx BV</div>
                        <div class="years">Jun 2013 – Aug 2013</div>
                        <div class="location">Ukraine, Kiev</div>
                    </div>
                    <div class="right">
                        <div class="specialization">BACKEND DEVELOPER</div>
                        <div class="text">
                            Daxx BV One of the leading tourism projects Holland.
                            <ul>
                                <li>
                                    Remote development of CMS management of tourist leisure
                                    offerings.
                                </li>
                                <li>
                                    Development and support of solutions based on Zend Framework
                                    v.1
                                </li>
                                <li>Developed the back end using PHP.</li>
                                <li>Support solutions using JavaScript, jQuery</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Freelancer(Web developer / web designer)</div>
                        <div class="years">May 2011 – Jun 2013</div>
                    </div>
                    <div class="right">
                        <div class="specialization">WEB DEVELOPER / WEB DESIGNER</div>
                        <div class="text">
                            Web development projects of varying complexity.

                            <ul>
                                <li>
                                    Extensive end user interaction for gathering requirements,
                                    design and UI approvals and User acceptance testing.
                                </li>
                                <li>
                                    Performed extensive analysis of system and business
                                    requirements, and business rules.
                                </li>
                                <li>Developed the Object Model using Visio.</li>
                                <li>
                                    Developed the front end using HTML using Javascript for client
                                    side validation, jQuery.
                                </li>
                                <li>Developed the back end using PHP.</li>
                                <li>Designed multimedia presentations for various events.</li>
                                <li>
                                    Designed templates for web projects (slicing, image
                                    optimization, etc.)
                                </li>
                                <li>
                                    Tool development using PHP to display and create XML data
                                    (JSON).
                                </li>
                                <li>Documentation.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">
                            Azov Regional Institute of Management Zaporizhzhya National
                            Technical University
                        </div>
                        <div class="years">Sep 2006 - May 2011</div>
                        <div class="location">Ukraine, Berdyansk</div>
                        <br/><br/>
                    </div>
                    <div class="right">
                        <div class="specialization">WEB DEVELOPER</div>
                        <div class="text">
                            Web development/support main university site and scientific
                            projects.
                        </div>
                    </div>
                </div>

                <div class="section-title-end"></div>
            </section>

            <section class="education">
                <div class="section-title">EDUCATION</div>

                <br/>

                <div class="row">
                    <div class="left">
                        <div class="company">Udacity</div>
                        <div class="years">2014 - 2014</div>
                    </div>
                    <div class="right">
                        <div class="specialization">CS256</div>
                        <div class="text">Mobile Web Development</div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">HARVARD X</div>
                        <div class="years">2013 - 2013</div>
                    </div>
                    <div class="right">
                        <div class="specialization">CS50x</div>
                        <div class="text">Introduction to Computer Science</div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">
                            Academy of Management and Information Technology
                        </div>
                        <div class="years">2004 - 2005</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Master’s degree</div>
                        <div class="text">Management Information Systems</div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">
                            Academy of Management and Information Technology
                        </div>
                        <div class="years">2001 - 2004</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Bachelor's degree</div>
                        <div class="text">Management Information Systems</div>
                    </div>
                </div>
                <div class="section-title-end"></div>
            </section>

            <section class="certifications">
                <div class="section-title">CERTIFICATIONS</div>

                <br/>

                <div class="row">
                    <div class="left">
                        <div class="company">HARVARD X</div>
                        <div class="years">Apr 2013</div>
                    </div>
                    <div class="right">
                        <div class="specialization">
                            CS50x: Introduction to Computer Science I
                        </div>
                        <div class="text">
                            License a4cf1c641ae94b108fbaeb47ddc18ff0 <br/>
                            <a
                                    href="https://verify.edx.org/cert/a4cf1c641ae94b108fbaeb47ddc18ff0"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">The Linux Foundation</div>
                        <div class="years">Aug 2014</div>
                    </div>
                    <div class="right">
                        <div class="specialization">LFS101x: Introduction to Linux</div>
                        <div class="text">
                            License 685b45fd68cb441d9533a744c346652a
                            <a
                                    href="https://verify.edx.org/cert/685b45fd68cb441d9533a744c346652a"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">AQUENT Gymnasium</div>
                        <div class="years">Sep 2014</div>
                    </div>
                    <div class="right">
                        <div class="specialization">jQuery Building Blocks</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/i1vow0ceiL4qIKYb2"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">AQUENT Gymnasium</div>
                        <div class="years">Mar 2015</div>
                    </div>
                    <div class="right">
                        <div class="specialization">JavaScript Foundations</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/iAWJkgPN0IXqWsfK2"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Nov 2017</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Angular CLI</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/sgay0GilKhVq3HA83"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Nov 2017</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Angular 5: Getting started</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/jDJUTNs6x1JQ9yXj1"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Dec 2017</div>
                    </div>
                    <div class="right">
                        <div class="specialization">SVG Fundamentals</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/Jp2W9W88ZAMqesl23"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Dec 2017</div>
                    </div>
                    <div class="right">
                        <div class="specialization">
                            Docker for Web Developers with John Papa and Dan Wahlin
                        </div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/Tgk1o3bLC9HLRsaS2"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Feb 2018</div>
                    </div>
                    <div class="right">
                        <div class="specialization">
                            Creating a Chrome Extension by John Sonmez
                        </div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/7EuPb0hANgxfgEH72"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Nov 2018</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Node.js: Getting Started</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/X4onLkYYzc3961cMA"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Nov 2018</div>
                    </div>
                    <div class="right">
                        <div class="specialization">NPM: Playbook by Joe Eames</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/KVBExRH9fFJZWC6j6"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Jan 2019</div>
                    </div>
                    <div class="right">
                        <div class="specialization">React: The Big picture</div>
                        <div class="text">
                            <a href="https://cloud.mail.ru/public/3RVx/YFhYofcpf"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Jan 2019</div>
                    </div>
                    <div class="right">
                        <div class="specialization">React fundamentals</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/xgh7djyLjxSBd6bY8"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">March 2019</div>
                    </div>
                    <div class="right">
                        <div class="specialization">
                            RxJS: The Big Picture by Brice Wilson
                        </div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/Pkk8oAu6e5jBWnNDA"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">Apr 2019</div>
                    </div>
                    <div class="right">
                        <div class="specialization">RxJS: Getting Started</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/zVbARHw45iCGpJ9K7"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">May 2020</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Svelte: Getting Started</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/wi8Fdy5HGLdraJhu7"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="left">
                        <div class="company">Pluralsight</div>
                        <div class="years">July 2020</div>
                    </div>
                    <div class="right">
                        <div class="specialization">Vue.js Fundamentals by Jim Cooper</div>
                        <div class="text">
                            <a href="https://photos.app.goo.gl/pimT8NAGfo9F1ho79"
                            >Certificate</a
                            >
                        </div>
                    </div>
                </div>

                <div class="section-title-end"></div>
            </section>

            <section class="three-columns">
                <div class="col-wrapper">
                    <div class="col">
                        <div class="section-title">SKILLS</div>
                        <span class="text minimal">
              EcmaScript (JavaScript), Typescript, PHP, NodeJs, CSS, SCSS, LESS,
              APACHE, NGINX, AWS EC2, S3, LAMBDA, Digitalocean, MySQL, MongoDb,
              Mongoose, D3, Adobe Photoshop, Adobe Illustrator, Adobe XD, MacOS,
              Windows, Linux (RPM/DEB), Bash, JSON, XML, Grunt, Gulp, Webpack,
              Browserify, VIM, Git, SVN and many many more...
            </span>
                    </div>
                    <div class="col">
                        <div class="section-title">FRAMEWORKS</div>
                        <span class="text minimal">
              Yii 1 - 2, D3, Google Charts, JQuery, JqueryUI, Twitter Bootstrap
              2 - 4, AngularJs, Angular, Vue, React, Svelte, Angular UI,
              ThreeJS, Electron, SocketIO, Guzzle, Mink, Mongoose, Ionic,
              Cordova, Express, HapiJs, Casper JS, PhantomJS, Headless Chrome
              and many many more...
            </span>
                    </div>
                    <div class="col languages">
                        <div class="section-title">LANGUAGES</div>
                        <span class="text">
              <ul>
                <li>English</li>
                <li>Russian</li>
                <li>Ukrainian</li>
                <li>Hebrew</li>
              </ul>
            </span>
                    </div>
                </div>
                <div class="section-title-end"></div>
            </section>

            <section class="recommendations">
                <div class="section-title">
                    RECOMMENDATIONS AND ADDITIONAL CERTIFICATES
                </div>
                <span class="text">
          Recommendations available
          <a href="https://www.linkedin.com/in/alexey-rubinchyk-0b511a51/"
          >LinkedIn - Section "Recommendations"</a
          >
        </span>
                <div class="section-title-end"></div>
            </section>
            <br/><br/>
        </div>
    </div>
</div>
