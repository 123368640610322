import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ResumeComponent } from "./components/resume/resume.component";
import { MainComponent } from "./components/main/main.component";
import { ProjectsComponent } from "./components/projects/projects.component";
import { WallOfHonorComponent } from './components/wall-of-honor/wall-of-honor.component';

const routes: Routes = [
  { path: "", component: MainComponent },
  { path: "resume", component: ResumeComponent },
  { path: "projects", component: ProjectsComponent },
  { path: "wall", component: WallOfHonorComponent },
  { path: "**", component: ResumeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
