import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxGalleryModule } from 'ngx-gallery-9';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResumeComponent } from './components/resume/resume.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MainComponent } from './components/main/main.component';
import { HeaderComponent } from './components/header/header.component';
import { VerticalSloganComponent } from './components/vertical-slogan/vertical-slogan.component';
import { SocialIconsComponent } from './components/social-icons/social-icons.component';
import { SliderComponent } from './components/slider/slider.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { SloganComponent } from './components/slogan/slogan.component';
import { SliderCounterComponent } from './components/slider-counter/slider-counter.component';
import { ProjectsComponent } from './components/projects/projects.component';
import {WallOfHonorComponent} from './components/wall-of-honor/wall-of-honor.component';
import {AutoDownloadComponent} from './components/auto-download/auto-download.component';


@NgModule({
  declarations: [
    AppComponent,
    ResumeComponent,
    SpinnerComponent,
    MainComponent,
    HeaderComponent,
    VerticalSloganComponent,
    SocialIconsComponent,
    SliderComponent,
    FooterMenuComponent,
    SloganComponent,
    SliderCounterComponent,
    ProjectsComponent,
    WallOfHonorComponent,
    AutoDownloadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGalleryModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
