import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';

@Component({
    selector: 'app-auto-download',
    templateUrl: './auto-download.component.html',
    styleUrls: ['./auto-download.component.scss']
})
export class AutoDownloadComponent implements OnInit {

    private downloadKey = 'jdoowk';
    // 3 days user will not be able to download file
    private ttl = 60 * 60 * 24 * 3;

    constructor() {
    }

    autoDownload() {
        const element = document.createElement('a');
        element.setAttribute('href', 'assets/docs/RubinchykResume.pdf');
        element.setAttribute('download', '');
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    ngOnInit(): void {
        if (!LocalStorageService.getWithExpiry(this.downloadKey)) {
            this.autoDownload();
            LocalStorageService.setWithExpiry(this.downloadKey, 1, this.ttl);
        }
    }

}
