<div class="slider-wrapper">
  <div class="slider-for-block" *ngFor="let slide of slides; let i = index">
    <div class="slider-if-block" *ngIf="active == i + 1">
      <div class="slider-text-wrapper">
        <div class="slider-title">{{ slide.title }}</div>
        <div class="slider-sub-title">{{ slide.sub }}</div>
        <div class="slider-text">
          {{ slide.description }}
        </div>
      </div>
      <div class="slider-icon-wrapper">
        <div class="slider-icon {{ slide.icon }}"></div>
      </div>
      <div class="right-side">
        <app-slider-counter
          (slideChange)="slideChange($event)"
          [active]="active"
          [slides]="slides"
        ></app-slider-counter>
      </div>
    </div>
  </div>
</div>
