export const wallOfHonorsImages = [
  {
    small: "/assets/wall/a3.png",
    medium: "/assets/wall/a3.png",
    big: "/assets/wall/a3.png",
    description: "Master's degree in \"Finance\". Azov regional institute of management. (Ukraine)",
  },
  {
    small: "/assets/wall/a4.png",
    medium: "/assets/wall/a4.png",
    big: "/assets/wall/a4.png",
    description: "Master's degree in \"Management of foreign economic activity\". Azov regional institute of management. (Ukraine)",
  },
  {
    small: "/assets/wall/a1.png",
    medium: "/assets/wall/a1.png",
    big: "/assets/wall/a1.png",
    description: "Bachelor in Information Systems Management. Academy of Management and Information Systems. (Ukraine)",
  },
  {
    small: "/assets/wall/a2.png",
    medium: "/assets/wall/a2.png",
    big: "/assets/wall/a2.png",
    description: "Master in Management of Information Systems in Organizations. Academy of Management and Information Systems. (Ukraine)",
  },
  {
    small: "/assets/wall/8.png",
    medium: "/assets/wall/8.png",
    big: "/assets/wall/8.png",
    description: "Linux Foundation. LFS101x Honor code certificate",
  },
  {
    small: "/assets/wall/13.png",
    medium: "/assets/wall/13.png",
    big: "/assets/wall/13.png",
    description: "Certificate - Svelte: Getting Started",
  },
  {
    small: "/assets/wall/14.png",
    medium: "/assets/wall/14.png",
    big: "/assets/wall/14.png",
    description: "Certificate - SVG Fundamentals",
  },
  {
    small: "/assets/wall/15.png",
    medium: "/assets/wall/15.png",
    big: "/assets/wall/15.png",
    description: "Certificate - VueJs Fundamentals",
  },
  {
    small: "/assets/wall/9.png",
    medium: "/assets/wall/9.png",
    big: "/assets/wall/9.png",
    description: "Certificate - NodeJs Getting Started",
  },
  {
    small: "/assets/wall/11.png",
    medium: "/assets/wall/11.png",
    big: "/assets/wall/11.png",
    description: "Certificate - RxJS - The Big picture",
  },
  {
    small: "/assets/wall/12.png",
    medium: "/assets/wall/12.png",
    big: "/assets/wall/12.png",
    description: "Certificate - RxJS - Getting Started",
  },
  {
    small: "/assets/wall/1.png",
    medium: "/assets/wall/1.png",
    big: "/assets/wall/1.png",
    description: "Certificate - Angular CLI",
  },
  {
    small: "/assets/wall/2.png",
    medium: "/assets/wall/2.png",
    big: "/assets/wall/2.png",
    description: "Certificate - Angular Getting Started",
  },
  {
    small: "/assets/wall/3.png",
    medium: "/assets/wall/3.png",
    big: "/assets/wall/3.png",
    description:
      "Certificate - Play By Play: Docker for web developers with John Papa and Dan Wahlin",
  },
  {
    small: "/assets/wall/4.png",
    medium: "/assets/wall/4.png",
    big: "/assets/wall/4.png",
    description: "Certificate - Creating a Chrome extension",
  },
  {
    small: "/assets/wall/7.png",
    medium: "/assets/wall/7.png",
    big: "/assets/wall/7.png",
    description: "Gymnasium - JavaScript Foundations",
  },
  {
    small: "/assets/wall/6.png",
    medium: "/assets/wall/6.png",
    big: "/assets/wall/6.png",
    description: "Gymnasium - jQuery Building Blocks",
  },
  {
    small: "/assets/wall/10.png",
    medium: "/assets/wall/10.png",
    big: "/assets/wall/10.png",
    description: "Certificate - NPM Playbook",
  },
  {
    small: "/assets/wall/5.png",
    medium: "/assets/wall/5.png",
    big: "/assets/wall/5.png",
    description: "Harvard - Computer Science 50x",
  },
];
