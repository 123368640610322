<div class="footer-menu-wrapper">
  <div class="footer-menu-title">MAIN MENU</div>

  <div class="footer-buttons-wrapper">
    <div class="button-wrapper home"
         routerLink="/"
         routerLinkActive="active">
      <div class="button-background">HOME</div>
      <div class="button-title"></div>
    </div>

    <div
      class="button-wrapper resume"
      routerLink="/resume"
      routerLinkActive="active"
    >
      <div class="button-background"></div>
      <div class="button-title">RESUME</div>
    </div>

    <a class="button-wrapper blog" href="//blog.rubinchyk.com" target="_blank">
      <div class="button-background"></div>
      <div class="button-title">BLOG</div>
    </a>

    <div class="button-wrapper laboratories">
      <div class="button-background"></div>
      <div class="button-title">LABORATORIES</div>
    </div>

    <div class="button-wrapper projects"
         routerLink="/projects"
         routerLinkActive="active">
      <div class="button-background"></div>
      <div class="button-title">PROJECTS</div>
    </div>

    <div class="button-wrapper works">
      <div class="button-background">WORKS</div>
      <div class="button-title"></div>
    </div>

    <div class="button-wrapper wall-of-honors"
         routerLink="/wall"
         routerLinkActive="active">
      <div class="button-background">WALL OF HONOR</div>
      <div class="button-title"></div>
    </div>

  </div>
</div>
