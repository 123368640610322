import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider-counter',
  templateUrl: './slider-counter.component.html',
  styleUrls: ['./slider-counter.component.scss']
})
export class SliderCounterComponent implements OnInit {

  constructor() {
  }

  @Input() slides: [];

  @Input() active: number;

  @Output() slideChange: EventEmitter<string> = new EventEmitter<string>();

  slideChanger(slideNum) {
    slideNum = slideNum + 1;
    this.slideChange.emit(slideNum);
  }

  ngOnInit(): void {
  }

}
