import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-slogan',
  templateUrl: './vertical-slogan.component.html',
  styleUrls: ['./vertical-slogan.component.scss']
})
export class VerticalSloganComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
