import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() {
    }

    public static setWithExpiry(key: string, value: number, ttl: number): void {
        const now = new Date();

        const item = {
            value,
            expiry: now.getTime() + ttl
        };
        window.localStorage.setItem(key, JSON.stringify(item));
    }

    public static getWithExpiry(key: string): string {
        const itemStr = window.localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            window.localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
}
