<div class="projects">
  <p class="project-container">
    <a href="https://news-israel.com" target="_blank">
      <img
        src="assets/img/projects_news-israel.png"
        class="project-poster"
        alt="news-israel.com"
      />

      <div class="title">
        The project was created for my wife. Special crawlers collect
        information every 5 minutes from more than 40 sources, rank news,
        determine the relevance by visitors and rate news. There is a Russian
        version with Russian language news, as well as a Hebrew version with Hebrew language news.
      </div>
    </a>
    <a href="https://anchor.fm/frontend-news" target="_blank">
      <img
        src="assets/img/projects_podcast_frontend_news.png"
        class="project-poster"
        alt="Podcast Frontend News"
      />

      <div class="title">
        The latest news related to Web Development, articles, releases, conferences and much more. Available on Amazon Music, Apple Podcasts, Google Podcasts, Spotify, Anchor, Castbox, Pocket Casts, RadioPublic, Stitcher, TuneIn, RSS.
      </div>
    </a>
  </p>
</div>
